// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-tags-index-jsx": () => import("./../src/pages/tags/index.jsx" /* webpackChunkName: "component---src-pages-tags-index-jsx" */),
  "component---src-templates-about-page-jsx": () => import("./../src/templates/about-page.jsx" /* webpackChunkName: "component---src-templates-about-page-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-contact-page-jsx": () => import("./../src/templates/contact-page.jsx" /* webpackChunkName: "component---src-templates-contact-page-jsx" */),
  "component---src-templates-index-page-jsx": () => import("./../src/templates/index-page.jsx" /* webpackChunkName: "component---src-templates-index-page-jsx" */),
  "component---src-templates-tags-jsx": () => import("./../src/templates/tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */)
}

